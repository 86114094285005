import DemoComponent from '../components/DemoComponent';
import { useAppContext } from '../context/AppContext';

export default function Test() {
  const { counter, incrementCounter } = useAppContext();
  
  return (
    <div className="space-y-4">
      <h1 className="text-3xl font-bold" style={{ fontFamily: 'Belanosima', fontWeight: '400' }}>Test Page</h1>
      <div>
        <p>Shared counter: {counter}</p>
        <button 
          onClick={incrementCounter}
          className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
        >
          Increment Shared Counter
        </button>
      </div>
      <DemoComponent />
    </div>
  );
}