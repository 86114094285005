import GameAwardForm from '../components/GameAwardForm';
import TextArea from '../components/TextArea';

export default function Vote() {
    return (
        <TextArea>
            <h1 className="text-4xl font-bold mb-6 text-[#fff444]" style={{ fontFamily: 'Belanosima', fontWeight: '400' }}>Vote for the YAGA Nominees</h1>
            <p className="text-2xl text-gray-100 mb-2">
            Voting is closed for the 2024 Nominees. Thank you for participating!
            </p>
            <p className="text-1xl text-gray-100 mb-8">
            Voting will re-open soon to allow you to vote for the winners! Check back soon.
            </p>
        </TextArea>
    );
  }
