import React, { useState } from 'react';

const DemoComponent = () => {
  const [count, setCount] = useState(0);
  const [inputText, setInputText] = useState('');

  const handleIncrement = () => {
    setCount(count + 1);
  };

  return (
    <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md">
      <h1 className="text-2xl font-bold mb-4">Welcome to React!</h1>
      
      <div className="space-y-4">
        <div>
          <p className="mb-2">Counter: {count}</p>
          <button 
            onClick={handleIncrement}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Increment
          </button>
        </div>

        <div>
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Type something..."
            className="border p-2 rounded w-full"
          />
          <p className="mt-2">You typed: {inputText}</p>
        </div>
      </div>
    </div>
  );
};

export default DemoComponent;