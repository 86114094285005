// components/ParallaxBackground.js
import { useState, useEffect } from 'react';

const ParallaxBackground = ({ layers }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
  
    useEffect(() => {
      // Enable horizontal scrolling
      document.body.style.overflowY = 'scroll';
      //document.body.style.width = '200vw'; // Give some scroll room
  
      const handleScroll = () => {
        setScrollPosition(window.scrollY);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
        document.body.style.overflowY = 'auto';
        document.body.style.width = '100%';
      };
    }, []);

  return (
    <div className="fixed object-contain inset-0 overflow-hidden -z-10">
      {layers.map((layer, index) => (
        <div 
          key={index}
          className="absolute inset-0 bg-repeat-x"
          style={{
            backgroundImage: `url(${layer.image})`,
            backgroundRepeat: 'repeat',
            backgroundPosition: `${(-scrollPosition * layer.speed)}px 0`,
            backgroundSize: 'cover', // Changed this to cover
            imageRendering: 'pixelated'
          }}
        />
      ))}
    </div>
  );
};

export default ParallaxBackground;