import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className="w-full bg-[#000069] text-[#fff444]" style={{"user-select": "none"}}>
      <div className="w-full mx-auto flex flex-col items-center">
        <Link to="/" className="grid w-full text-6xl font-bold hover:text-[#fff444]  active:text-[#fff444] transition-colors" style={{ fontFamily: 'Anton', fontWeight: '100' }}>
            <img
                src="/headernarrowbg.webp"
                alt="YAGA"
                className="w-full sm:h-48 xl:h-64 object-contain hover:opacity-90 active:opacity-80 transition-opacity"
                style={{ 'grid-row': '1', 'grid-column': '1' }}
            />
            <img
                src="/headernarrowlogotextbg.webp"
                alt="YAGA"
                className=" w-full sm:h-48 xl:h-64 object-contain hover:opacity-90 active:opacity-80 transition-opacity"
                style={{ 'grid-row': '1', 'grid-column': '1' }}
            />
            <img
                src="/headernarrowlogotext.webp"
                alt="YAGA"
                className="w-full sm:h-48 xl:h-64 object-contain opacity-90 hover:opacity-80 active:opacity-70 transition-opacity"
                style={{ 'grid-row': '1', 'grid-column': '1' }}
            />
            {/* <img 
                src="/header512narrow.webp"
                alt="YAGA" 
                className="w-full h-64 object-fill hover:opacity-90 active:opacity-80 transition-opacity"  // Adjust height as needed
            /> */}
        </Link>
      </div>
    </header>
  );
}