import { Link } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import CardDisplay from '../components/CardDisplay';
import TextArea from '../components/TextArea';

export default function Home() {
  const { sharedMessage, setSharedMessage } = useAppContext();
  const sampleCards = [
    {
      imageUrl: "judero.jpg",
      title: "Judero",
      description: "Talha and Jack Co, J. King-Spooner, Talha Kaya",
      publisher: "Talha and Jack Co"
    },
    {
      imageUrl: "1000x.jpg",
      title: "Second Card",
      description: "sunset visitor 斜陽過客",
      publisher: "Fellow Traveller"
    },
    // Add more cards as needed
  ];
  
  return (
    <TextArea>
      <h1 className="text-4xl font-bold mb-6 text-[#fff444]" style={{ fontFamily: 'Belanosima', fontWeight: '400' }}>YAGA 2024 Nominees</h1>
      <div>
        {/* <CardDisplay cards={sampleCards} columns={3} /> */}
        <p className="text-2xl text-gray-100 px-6" style={{ fontFamily: 'ABeeZee' }}>
        YAGA is accepting audience nominations for your favorite games and developers of 2024.
        <br></br><br></br>
        Click ↴ to vote!
        </p>
        <br></br>
        <Link to="/vote" className="mx-5 px-5 py-2 text-2xl text-gray-900 hover:bg-yellow-500 active:bg-yellow-600 transition-colors bg-[#fff444] rounded-1g" style={{"border-radius": "5px !important"}}>Vote Now!</Link>
      </div>
    </TextArea>
  );
}