import { Link } from 'react-router-dom';

// components/Footer.js
export default function Footer() {
    return (
      <footer className="text-[.5rem] xl:text-[1rem] bg-[#000069] text-white mt-auto" style={{ fontFamily: 'ABeeZee' }}>
        <div className="w-[98%] xl:w-[60%] mx-auto py-4 px-4">
          <div className="flex justify-between items-center space-evenly">
            <div>
              © 2024 YAGA
            </div>
            <div>|</div>
            <div>
              Website by Nick Saris @ nsar.bsky.social
            </div>
            <div>|</div>
            <div>
              Backgrounds by Ansimuz @ ansimuz.itch.io
            </div>
            <div>|</div>
            <div className="space-x-4">
              <Link to="/about" className="hover:text-blue-300">About</Link>
              <Link to="https://yagashow.bsky.social" target="_blank" className="hover:text-blue-300">Bluesky</Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }